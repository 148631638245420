<template>
  <div class="contract">
    <sm-editable-item
      v-model="form"
      :isLoading="isLoadingPage"
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="fields"
      saveButtonText="Создать"
      :isLoadingSaveButton="isLoadingSaveButton"
      @save="onSave"
      @cancel="onCancel('CustomersMain')"
    >
      <template v-slot:idClient="{ field }">
        <p class="editable-form__description">{{ field.label }}</p>
        <sm-select
          v-model="form.idClient"
          class="contract__custorer-select"
          search
          :options="customers.data"
        />
        <div class="contract__custorer-code">Код {{ form.idClient }}</div>
      </template>

      <template #tab-bottom-panel-0>
        <sm-textarea
          v-model="contractInfo"
          class="contract__info"
          label="Результат создания договора"
          :disabled="true"
          :rows="5"
          :maxRows="5"
        />
        <div class="products">
          <div
            v-for="(_, index) in form.products"
            :key="`product-${index}`"
            class="product"
          >
            <div class="product__header">
              <h3>Услуга {{ index + 1 }}</h3>
              <button
                class="product__delete-button"
                @click="openDeleteProductModal"
              >
                <sm-icon name="trash" />
              </button>
            </div>

            <div class="product__form editable-form">
              <div class="editable-form__column">
                <sm-select
                  v-model="form.products[index].idProduct"
                  search
                  :options="
                    products.data.map((item) => ({
                      name: item.name,
                      value: item.id,
                    }))
                  "
                  label="Номенклатура"
                />
              </div>
              <div class="editable-form__column">
                <sm-numeric-input
                  v-model="form.products[index].price"
                  label="Цена"
                  :min="11"
                  :max="15"
                  @input="calculateProductSum(index)"
                />
              </div>
              <div class="editable-form__column">
                <sm-numeric-input
                  v-model="form.products[index].volume"
                  label="Количество"
                  @input="calculateProductSum(index)"
                />
              </div>
              <div class="editable-form__column">
                <!-- <sm-button
                  class="product__calculate-button"
                  @click="calculateProductSum(index)"
                  >Расчитать</sm-button
                > -->
                <sm-numeric-input
                  v-model="form.products[index].sum"
                  label="Сумма"
                />
              </div>
            </div>
          </div>

          <div
            v-show="form.products.length"
            class="product__form-total editable-form"
          >
            <div class="editable-form__column">
              <sm-numeric-input
                :value="totalSum"
                label="Итоговая сумма по договору"
                :disabled="true"
              />
            </div>
          </div>

          <div class="products__footer">
            <button class="products__add-button" @click="onAddProduct">
              <sm-icon name="plus" />
              Добавить услугу
            </button>
          </div>
        </div>
      </template>
    </sm-editable-item>

    <!-- <sm-modal
      class="contact__modal"
      :show="showInfoModal"
      modalTitle="Информация о договоре"
      @close="onCancel"
    >
      <template #body>
        <p class="contact__modal-text">
          {{ contractInfo.data }}
        </p>
      </template>
    </sm-modal> -->
  </div>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmTextarea from '@/components/common/forms/SmTextarea.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmNumericInput from '@/components/common/forms/SmNumericInput.vue';
// import SmButton from '@/components/common/buttons/SmButton.vue';
// import SmModal from '@/components/common/modals/SmModal.vue';

export default {
  name: 'ContractCreate',

  components: {
    SmEditableItem,
    SmTextarea,
    SmIcon,
    SmSelect,
    SmNumericInput,
    // SmButton,
    // SmModal,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'OneCContracts',
      pageHeader: 'Создание договоров',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Создание договоров',
        },
      ],
      // Для тестов
      // initialForm: {
      //   number: '01',
      //   date: '09.11.2022',
      //   idTypeContract: '000000021',
      //   idClient: 14565,
      //   inn: '6632021704',
      //   periodic: 'Полугодие',
      //   inn_organization: '2224100238',
      //   products: [
      //     {
      //       idProduct: '00-00000024',
      //       price: 1600.0,
      //       volume: 2.5,
      //       sum: 4000.0,
      //     },
      //     {
      //       idProduct: '00-00000255',
      //       price: 750.0,
      //       volume: 2,
      //       sum: 1500.0,
      //     },
      //   ],
      // },
      initialForm: {
        number: null,
        date: null,
        idTypeContract: null,
        idClient: null,
        inn: null,
        periodic: null,
        inn_organization: null,
        products: [],
      },
      innOptions: [
        { value: '2224100238', name: 'НИЦ "Системы управления", 2224100238' },
        { value: '500110123915', name: 'ИП Рудковский Д.Е. 500110123915' },
        { value: '222403522675', name: 'ИП Ращупкин В.Н. 222403522675' },
      ],
      form: null,
      initialProductForm: {
        idProduct: null,
        price: 0,
        volume: 0,
        sum: 0,
      },
      // showInfoModal: false,
      contractInfo: '',
    };
  },

  computed: {
    ...mapState({
      periodList: (state) => state.oneCContracts.periodList,
      products: (state) => state.oneCContracts.products,
      contractTypes: (state) => state.oneCContracts.contractTypes,
      // contractInfo: (state) => state.oneCContracts.contractInfo,
      customers: (state) => state.common.customers,
    }),

    // sortedCustomers() {
    //   return this.customers.data.sort((a, b) => {
    //     const nameA = a.name.toLowerCase();
    //     const nameB = b.name.toLowerCase();
    //     if (nameA < nameB) {
    //       return -1;
    //     }

    //     if (nameA > nameB) {
    //       return 1;
    //     }

    //     return 0;
    //   });
    // },

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'number',
              label: 'Номер',
            },
            {
              type: 'date',
              key: 'date',
              label: 'Дата договора',
            },
            {
              type: 'select',
              key: 'inn_organization',
              label: 'Организация',
              list: this.innOptions,
            },
            {
              type: 'select',
              key: 'idClient',
              label: 'Клиент',
              list: this.customers.data,
            },
            {
              type: 'text',
              key: 'inn',
              label: 'ИНН Контрагента',
            },
            {
              type: 'select',
              key: 'periodic',
              label: 'Периодичность договора',
              list: this.periodList.data?.map((item) => ({
                name: item,
                value: item,
              })),
            },
            {
              type: 'select',
              key: 'idTypeContract',
              label: 'Вид договора',
              list: this.contractTypes.data?.map((item) => ({
                name: item.name,
                value: item.id,
              })),
            },
          ],
        },
      ];
    },

    totalSum() {
      return this.form.products
        .map((item) => item.sum)
        .reduce((previousVal, currentVal) => previousVal + currentVal, 0);
    },
  },

  watch: {
    'form.inn_organization': {
      handler(newValue) {
        if (newValue) {
          console.log(this.form);
          const { inn_organization } = this.form;
          this.getContractTypes({ inn: inn_organization });
          this.form.idTypeContract = null;
        }
      },
    },

    'form.idTypeContract': {
      handler(newValue) {
        if (newValue) {
          const { inn_organization, idTypeContract } = this.form;
          if (inn_organization && idTypeContract) {
            this.getProducts({
              inn: inn_organization,
              product: idTypeContract,
            });
          }
        }
      },
    },
  },

  created() {
    this.isLoadingPage = true;

    const periodList = this.getPeriodList();
    // const products = this.getProducts();
    // const contractTypes = this.getContractTypes();
    const customers = this.getCommonList({ name: 'Customers' });

    Promise.all([periodList, customers]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getPeriodList: 'oneCContracts/getPeriodList',
      getProducts: 'oneCContracts/getProducts',
      getContractTypes: 'oneCContracts/getContractTypes',
      createContract: 'oneCContracts/createContract',
      getCommonList: 'common/getCommonList',
    }),

    // onInput({ key }) {
    //   const { inn_organization, idTypeContract } = this.form;

    //   if (!['inn_organization', 'idTypeContract'].includes(key)) {
    //     return;
    //   }

    //   if (key === 'inn_organization') {
    //     this.getContractTypes({ inn_organization });
    //   }

    //   if (inn_organization && idTypeContract) {
    //     this.getProducts({ inn_organization, idTypeContract });
    //   }
    // },

    onAddProduct() {
      this.form.products.push(this.lodash.cloneDeep(this.initialProductForm));
    },

    openDeleteProductModal(index) {
      this.$root
        .$confirmModal({
          message: 'Удалить услугу?',
        })
        .then((response) => {
          if (response) {
            this.form.products.splice(index, 1);
          }
        });
    },

    calculateProductSum(index) {
      this.form.products[index].sum = +(
        this.form.products[index].price * this.form.products[index].volume
      ).toFixed(2);
    },

    onSave() {
      this.isLoadingSaveButton = true;

      this.createContract(this.form)
        .then((result) => {
          this.contractInfo = result;
          // this.showInfoModal = true;
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },

    // onCancel() {
    //   const statusList = [
    //     'ok:',
    //     'Ok:',
    //     'OK:',
    //     'oK:',
    //     'ок:',
    //     'Ок:',
    //     'ОК:',
    //     'оК:',
    //   ];

    //   statusList.some((status) => {
    //     if (this.contractInfo.data.startsWith(status)) {
    //       this.$router.push({ name: 'CustomersMain' });
    //       return true;
    //     }
    //     return false;
    //   });

    //   this.showInfoModal = false;
    // },
  },
};
</script>

<style lang="scss">
.contract .numeric-input__buttons {
  top: 30px;
}

.contract__custorer-select {
  width: 80%;
}

.contract__custorer-code {
  align-self: center;
  width: 20%;

  text-align: center;
  color: var(--red);
}

.contract__info {
  margin-bottom: 15px;
}

.product__header {
  display: flex;
  margin-bottom: 15px;
}

.product__delete-button {
  font-size: 0;
  color: var(--gray);

  &:hover {
    color: var(--red);
  }
}

.products__footer {
  margin-bottom: 50px;
}

.products__add-button {
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: 500;
  line-height: 1;
  color: var(--gray);

  border: none;
  background: transparent;

  transition: color 0.3s;

  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
}

// .product__calculate-button {
//   align-self: flex-end;

//   width: auto;
//   margin-right: 15px;
// }

.product__form-total {
  justify-content: flex-end;
}

// .contact__modal-text {
//   word-wrap: break-word;
// }
</style>
